<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add a new news item</h4>
    </div>

    <div class="modal-body">
      <loading :active.sync="isPageLoading"></loading>
      <template v-if="!isPageLoading">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="title">News Title <span class="red-dot ml-3"></span></label>
            <input id="title" name="title" type="text" maxlength="50" class="form-control" :class="{ 'is-invalid': errors.has('title') }"
              autocomplete="off" placeholder="Enter News Title" v-validate="'required'" v-model="news.title" data-vv-as="News Title" />
            <div class="invalid-feedback">The News Title field is required.</div>
          </div>

          <div class="col-md-12 mb-3">
            <label for="summary">News Summary <span class="red-dot ml-3"></span></label>
            <input id="summary" name="summary" type="text" maxlength="150" class="form-control" :class="{ 'is-invalid': errors.has('summary') }"
              autocomplete="off" placeholder="Enter News Summary" v-validate="'required'" v-model="news.summary" data-vv-as="News Summary" />
            <div class="invalid-feedback">The News Summary field is required.</div>
          </div>

          <div class="col-md-12 mb-3">
            <label for="link">News Link <span class="red-dot ml-3"></span></label>
            <input id="link" name="link" type="text" class="form-control" :class="{ 'is-invalid': errors.has('link') }"
              autocomplete="off" placeholder="Enter News Link" v-validate="{ required: true, url: { require_protocol: true }}" v-model="news.link" data-vv-as="News Link" />
            <div class="invalid-feedback">{{ errors.first('link') }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="mb-0">Apply To <span class="red-dot ml-3"></span></label>
          <small id="passwordHelpBlock" class="form-text text-muted mt-0 mb-1">
            Disabled options indicate that the zones has reached capacity ({{ maxAllowedPerZone }}).
          </small>
          <div id="shareWithZones">
            <input id="zone-d-none" name="zone" type="checkbox" class="d-none" v-model="news.zoneIds" v-validate="'required'" />
            <div v-for="(zone, index) in getZones" :key="zone.id" class="custom-control custom-checkbox">
              <input :id="'zone-' + index" name="zone" type="checkbox" class="custom-control-input" :class="{ 'is-invalid': errors.has('zone') }"
                :value="zone.id" :readonly="isZoneUnavailable(zone)" :disabled="isZoneUnavailable(zone)" v-model="news.zoneIds" v-validate="'required'">
              <label class="custom-control-label" :for="'zone-' + index">{{ zone.name }}</label>
              <div class="invalid-feedback" v-if="index === getZones.length - 1">Please select at least one zone.</div>
            </div>
          </div>
        </div>

      </template>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'news-article',
  data: function () {
    return {
      initialZones: [],
      isPageLoading: true,
      isEdit: false,
      maxAllowedPerZone: 5,
      news: {
        title: null,
        summary: null,
        link: null,
        zoneIds: []
      }
    }
  },
  computed: {
    ...mapGetters(["getNews", "getNewsListZoneEntriesCount", "getZones"])
  },
  methods: {
    isZoneUnavailable (zone) {
      if (this.initialZones.includes(zone.id))
        return false;

      return this.getNewsListZoneEntriesCount[zone.id] >= this.maxAllowedPerZone;
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }
      this.saveNews({ data: this.news, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-news-article");
    },
    ...mapActions(["saveNews", "fetchZones", "fetchNewsList"])
  },
  mounted: function() {
    this.news = { ...this.getNews };
    this.initialZones = this.news.zoneIds;
    this.isEdit = !!this.news.id;
    this.$validator.reset();
    this.fetchZones();

    this.isPageLoading = false;
  },
}
</script>
